/* comman */
body {
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
}

p {
  font-size: 13.64px;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 27.65px;
}

h3 {
  font-size: 17.26px;
}

a {
  text-decoration: none;
}

i {
  font-size: 18px;
}

.max-width {
  max-width: 250px;
}

.width-100 {
  width: 100%;
}

.cus-padd {
  padding: 30px;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.tinytext {
  font-size: 12.13px;
  color: #6a6a6a;
  margin-top: 6px;
}

.listpadding {
  padding-left: 30px;
  padding-right: 30px;
}

.addbtn2 {
  position: absolute;
  bottom: 4.5%;
  right: 3%;
}

.addbtn3 {
  position: absolute;
  bottom: 4.5%;
}

.fa-plus {
  margin-left: -2px;
}

.alreadytext {
  color: #867d7d;
  font-size: 17.26px;
}

.form-check-input:checked {
  background-color: #f47d3a;
  border-color: #f47d3a;
  color: #f47d3a;
}

.nobtn {
  width: 126px;
  height: 42px;
  border: 2px solid #f47d3a;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin-right: 8px;
}

.nobtn:hover {
  width: 126px;
  height: 42px;
  border: 2px solid #f47d3a;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  margin-right: 8px;
}

.yesbtn {
  width: 126px;
  height: 42px;
  background-color: #f47d3a;
  border-radius: 20px;
  color: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.yesbtn:hover {
  width: 126px;
  height: 42px;
  background-color: #f47d3a;
  border-radius: 20px;
  color: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.availability_table {
  border: none;
}

.err_msg {
  font-size: 12px;
  color: red;
}

.small-font {
  font-size: 14px;
}

.font17 {
  font-size: '17px';
}

.cusheight {
  height: 93vh;
  overflow: auto;
}

.cusheight::-webkit-scrollbar {
  display: none;
}

.cus-center {
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cus-signup-box {
  padding: 80px;
}

.bgcol {
  padding: 20px;
  height: 100%;
  background-image: linear-gradient(142.37deg,
      #ed3b68 -30.82%,
      #f5b77f 111.79%);
}

.heading-color {
  color: #f47d3a;
}

.cus-form label {
  color: #000;
  font-weight: 400;
}

.apptButton {
  background-color: #fbbf9e;
  color: white;
}

.cus-form .form-control {
  background-color: #fcf6f3;
  border: 1px solid #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.delete_offer {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
  border-radius: 5px;
}

.delete_offer_disable {
  background-color: #e9ecef;
  box-shadow: 0px 1px 3px #909193;
  border-radius: 5px;
  color: #c8c8c8;
}

.sidebtn {
  /* position: absolute; */
  position: sticky;
  /* bottom: 75px; */
  /* bottom: 60px;
  left: 80%; */
}

.colorbtun,
.disapearbtn {
  background: #f47d3a;
  border-radius: 24px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 0px;
  width: 200px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.resetbtn {
  width: 180px !important;
}

.disapearbtn {
  background: #d3d0ce;
}

.disapearbtn:hover {
  background: #d3d0ce;
  color: #fff;
  border: none;
}

.colorbtun:hover {
  background: #f47d3a !important;
  color: #fff !important;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.popupbtn {
  position: absolute;
  bottom: 10px;
  left: 28%;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.nav-tabs {
  border: none;
}

.activeNav {
  color: #f47d3a !important;
  font-size: 14px;
  background-color: white;
}

.unActiveNav {
  color: #000 !important;
  font-size: 14px;
  background-color: white;
}

.linebtn {
  width: 200px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 24px;
  color: rgb(255, 255, 255);
  padding: 6px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.linebtn2 {
  font-size: 21.85px;
}

.cus-margin {
  padding: 60px;
}

.inputwithicon i,
.inputwithicon1 i {
  position: absolute;
  left: 95%;
  top: 35px;
  padding: 9px 8px;
  font-size: 17.26px;
  color: #837c7c;
}

.inputwithicon,
.inputwithicon1 {
  position: relative;
}

.inputwithicon1 i {
  top: 6px;
}

.cus-link {
  color: #000;
  font-size: 13.64px;
}

.px {
  margin-top: 3em;
}

.px1 {
  margin-top: 3em;
  margin-left: -20px;
}

.cus-forgot .form-control {
  border-bottom: 1px solid black !important;
  border: none;
  border-radius: 0rem;
  box-shadow: none;
}

.bar2 {
  background-color: #7b98ff;
}

.bar1 {
  background-color: #ffbb54;
}

.listpadding2 {
  padding: 20px;
}

.innerhr {
  margin: 0px 0px 20px 0px;
}

/* pin code css */

.pin-code {
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.pin-code input {
  border: none;
  text-align: center;
  width: 42px !important;
  height: 42px;
  font-size: 35px;
  border: 1px solid #f47d3a;
  margin-right: 8px;
}

.pin-code input:focus {
  border: 1px solid #000;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* ends */
/* dashboard */

.dashbg {
  background-color: #fff2ec;
}

.main {
  overflow: hidden;
  padding: 15px;
}

html {
  scroll-behavior: smooth;
}

a:hover {
  text-decoration: none;
}

/* new section css start */

.boxx {
  border-radius: 7px;
  background-color: #fcf6f3;
  color: black;
  padding: 5px;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.newtabs li {
  color: #f47d3a;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #000000;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f47d3a;
  background-color: white;
  border-radius: 0px !important;
  border: none;
  border-bottom: 5px solid #f47d3a;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  color: #f47d3a;
  background-color: white;
  border-radius: 0px !important;
  border: none;
  border-bottom: 5px solid #f47d3a;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.modal-header {
  display: block !important;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.service_count {
  position: absolute;
  left: 75px;
  bottom: 70px;
  background: #f47d3a;
  border-radius: 50%;
  font-size: 12px;
  padding: 1px 7px;
  color: #fff;
}

.chat_notification {
  background: #f47d3a;
  border-radius: 50%;
  font-size: 12px;
  padding: 5px 9px;
  color: #fff;
}

/* new section css end */

@media (min-width: 576px) {
  .col-sm-9 {
    flex: 0 0 74%;
  }
}

.design:hover {
  background-color: #01b6d3 !important;
}

.main-body {
  height: 97vh;
  overflow-y: auto;
  background: #fff;
  width: 100%;
  border-radius: 25px;
  padding: 10px;
}

.left-nav-bar {
  width: 20%;
  height: 96vh;
  padding: 25px;
}

.my-2.my-lg-0 {
  margin-top: 2em !important;
  float: right;
  margin-bottom: 0.6em !important;
}

.text-clr {
  color: #f47d3a;
  font-weight: 500;
}

.list-sidebar {
  padding-inline-start: 0px;
  list-style: none;
}

.list-sidebar li {
  margin-bottom: 2em;
}

.offcanvas.offcanvas-end {
  top: 5px;
  right: 5px;
  bottom: 5px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.popupheader {
  font-size: 27.65px;
}

.table td {
  vertical-align: middle !important;
  text-align: center;
  border-style: none;
  font-size: 14px;
}

.table th {
  font-size: 16px;
}

.cus-table,
.readtable {
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.cus-table tr {
  background-color: #fcf6f3;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.notifaication_Tab {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.readtable {
  border: 1px solid #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.table {
  --bs-table-bg: none !important;
}

.tr,
.td {
  border-radius: 20px;
}

.cusgap {
  margin-right: 10px;
  vertical-align: middle;
}

.iconul {
  list-style: none;
  display: inline-flex;
  padding-inline-start: 0px;
}

.iconul li {
  margin-left: 10px;
}

th {
  font-size: 17.26px;
  font-weight: 500;
}

.cus-list {
  padding-inline-start: 0px;
  list-style: none;
}

.cus-list li,
.accordion-button,
.box {
  background-color: #fcf6f3;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #fbbf9e;
  padding: 15px;
  margin-bottom: 1em;
}

.cus-list li i {
  font-size: 24.58px;
  margin-right: 10px;
  color: #6a6a6a;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.accordion-item {
  background-color: #fffbfa;
  margin-bottom: 1em;
  border-radius: 10px !important;
  border: none;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.navicon {
  list-style: none;
  display: flex;
}

.navicon li {
  margin-left: 15px;
}

.navi {
  list-style: none;
  display: flex;
}

.navi ol,
ul {
  padding-left: 0rem;
}

.navicon li {
  margin-right: 10px;
}

.form-control {
  font-size: 16px;
}

.ablebranch {
  color: #000000 !important;
}

.fa-plus:before {
  cursor: pointer;
  background: #f47d3a;
  color: #fff;
  border-radius: 100%;
  padding: 6px;
  font-size: 18px;
  outline: none;
}

/* .plus_Btn_Disabled {
  background: #afafa3 !important;
  color: #fff;
  border-radius: 100%;
  padding: 3px 8px;
  font-size: 18px;
  margin-left: 11px;
  outline: none;
} */
.fa-minus:before {
  cursor: pointer;
  background: #f47d3a;
  color: #fff;
  border-radius: 100%;
  padding: 6px;
  font-size: 15px;
  outline: none;
}

.edit-btn {
  float: right;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.page-tittle {
  font-size: 20px;
}

.main-background {
  background-color: #feeada;
  height: 100vh;
  overflow: hidden;
}

.cursor {
  cursor: pointer;
}

.btn_Style {
  background: #f47d3a;
  color: #fff;
  font-size: 15px;
  border-radius: 10px;
}

.btn_Style:hover {
  background: #f47d3a;
  color: #fff;
  font-size: 15px;
  border-radius: 10px;
}

.cancel_btn {
  color: #6a6a6a;
  border-radius: 10px;
  border: 1px solid #ced4da;
}

.cancel_btn:hover {
  border-radius: 10px;
  color: #6a6a6a;
  border: 1px solid #ced4da;
}

.group_text {
  width: 100%;
  height: 40px;
  color: #6a6a6a;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ced4da;
}

.admin-text {
  font-size: 12px;
  background: #feeada;
  width: 80%;
  border-radius: 5px;
  padding: 2px 5px;
  color: green;
}

.member-text {
  font-size: 12px;
  background: #e5f7ff;
  color: #297eb3;
  width: 100%;
  border-radius: 5px;
  padding: 2px 5px;
}

/* Login SignUp Page Animation CSS Start */
.container2 {
  position: relative;
  width: 100%;
  background-color: #fff;
  height: 90vh;
  margin-top: 2%;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.forms-container2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

#signInSignUpForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#signInSignUpForm.sign-up-form {
  opacity: 0;
  z-index: 1;
}

#signInSignUpForm.sign-in-form {
  z-index: 2;
}

.input-field {
  max-width: 100%;
  width: 100%;
  background-color: #fcf6f3;
  border: 1px solid #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
  margin: 10px 0;
  height: 42px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 43px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-size: 1.1rem;
  color: #333;
}

.panels-container2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 6px 0 4px -4px #999, -6px 0 4px -4px #999;
}

.container2:before {
  content: '';
  position: absolute;
  height: 90vh;
  width: 2000px;
  top: 50%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(142.37deg,
      #ed3b68 -30.82%,
      #f5b77f 111.79%);
  transition: 1.8s ease-in-out;
  z-index: 6;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 2rem 11% 1rem 4%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
  margin: auto;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.right-panel .content {
  transform: translateX(800px);
}

.container2.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container2.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container2.sign-up-mode .signin-signup {
  left: 25%;
}

.container2.sign-up-mode #signInSignUpForm.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container2.sign-up-mode #signInSignUpForm.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container2.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container2.sign-up-mode .left-panel {
  pointer-events: none;
}

.container2.sign-up-mode .right-panel {
  pointer-events: all;
}

/* .forgot-pin {
  margin-left: 300px;
  color: #000;
  font-size: 13.64px;
} */

.forgot-pin:hover {
  color: #000;
}

@media (max-width: 870px) {
  .container2 {
    min-height: 800px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 90%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container2.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container2 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .panel .content {
    /* padding-right: 15%; */
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .container2:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container2.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container2.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container2.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container2.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  #signInSignUpForm {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .container2 {
    padding: 1.5rem;
    margin-top: 0;
  }

  .container2:before {
    bottom: 60%;
    left: 50%;
  }

  .container2.sign-up-mode:before {
    bottom: 35%;
    left: 50%;
  }
}

/* Insight */
.innerhr {
  margin: 0px 0px 20px 0px;
}

.insightlist {
  display: flex;
  justify-content: center;
  list-style: none;
}

.card1 {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
  width: 300px;
  height: 100px;
  text-align: center;
  padding: 20px;
  margin-right: 2em;
  border-radius: 10px;
}

.innerhr2 {
  margin: -2rem;
}

.listpadding2 {
  padding: 20px;
}

.demolist {
  display: flex;
  list-style: none;
  padding-inline-start: 0px;
}

.demolist li {
  margin-right: 10px;
}

.demolist li a.active {
  color: coral;
}

.feedbackul {
  display: flex;
  list-style: none;
  padding-inline-start: 0px;
}

.feedbackbg {
  background-color: #fcf6f3;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #fbbf9e;
  padding: 20px;
}

/* Login SignUp Page Animation CSS End */
/* @media (max-width: 1200px) {
  .forgot-pin {
    margin-left: 240px;
  }
} */

/* @media screen and (max-width: 990px) and (min-width: 870px) {
  .forgot-pin {
    margin-left: 136px;
  }
} */

.m-t-3 {
  margin-top: 30px !important;
}

.cus-availability-table-align>tr>td>ul {
  padding: 0 !important;
  margin: 0 !important;
}

.offcanvas-backdrop.fade.show {
  pointer-events: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.search>div {
  display: inline-block;
  position: relative;
  filter: drop-shadow(0 1px black);
}

.search>div:after {
  content: '';
  background: black;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 18px;
  right: -7px;
  transform: rotate(135deg);
}

.search>div>input {
  background: transparent;
  width: 25px;
  height: 8px;
  padding: 10px;
  border: solid 2px black;
  outline: none;
  border-radius: 35px;
  transition: width 0.5s;
  margin-right: -6px;
}

.search>div>input::placeholder {
  color: black;
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.search>div>input:focus::placeholder {
  opacity: 1;
}

.search>div>input:focus,
.search>div>input:not(:placeholder-shown) {
  width: 250px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #feeada;
}

.hoverover:hover .overlay {
  opacity: 1;
}

.overlay_chat {
  position: absolute;
  bottom: 0;
  left: 89%;
  right: 0;
  height: 100%;
  width: 100%;
  top: 40px;
  opacity: 0;
  transition: 0.2s ease;
}

.sideBar-main:hover .overlay_chat {
  opacity: 1;
}

.overlay_chat_send {
  opacity: 0;
  transition: 0.2s ease;
}

.msg_send:hover .overlay_chat_send {
  opacity: 1;
}

.text {
  font-size: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 450px;
  right: 45%;
}

.searchWrapper {
  border: none !important;
  padding: 0 !important;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__tagInput {
  width: 100%;
  display: inline-block;
  border-bottom: none;
}

.ReactTags__selected {
  border: 2px solid #f5f5f5;
  margin-top: 10px;
  height: 80px;
  overflow-y: auto;
  border-radius: 10px;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fcf6f3;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  border: 1px solid #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.ReactTags__selected span.ReactTags__tag {
  border: none;
  background: #fcf6f3;
  color: #000;
  font-size: 12px;
  display: inline-block;
  padding: 5px 12px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #f3cfbc;
}

.ReactTags__remove {
  color: #000 !important;
  margin-left: 10px;
  cursor: pointer;
}

.ReactTags__suggestions {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  background-color: #fff;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #f5f5f5;
}

.ReactTags__suggestions li {
  background-color: #f5f5f5;
  padding: 2px 17px;
  margin: 0;
  margin: 5px;
  border-radius: 20px;
}

.ReactTags__suggestions li mark {
  background: none;
  font-weight: 500;
  color: #f47d3a;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #fcf6f3;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
  color: #000 !important;
}

.css-319lph-ValueContainer {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.css-tlfecz-indicatorContainer {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

/* Reject Page Css */
.bgImg {
  background-image: url('./Assets/images/expire.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh !important;
  width: 100%;
}

.logo {
  color: #f47d3a;
}

.bgOrange {
  background: #f47d3a;
}

.bgOrange:hover {
  background: #f47d3a;

}

.textLight {
  color: #808080a1;
}

.bgOrange2 {
  background: #df540036;
}

.bgOrange2:hover {
  background: #df540036;

}


.reject-btn {
  border: 1px solid #f47d3a !important;
  color: white !important;
  background-color: #f47d3a !important;
  padding: 8px 25px 8px 25px !important;
}

.reject-btn:hover {
  background-color: #ff5e00 !important;
  color: #ffffff !important;
  border-color: #ff5e00 !important;
}

.padd {
  margin-top: 150px;
  padding-left: 20px;
}

/*////////////////////////////////////////////////////////// 404 err ///////////////////////////////////////////////////////////////////*/
.minbody {
  background-color: #f47e3a80;
}

#handboy {
  animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 98% 98%;
  transform-box: fill-box;
}

#girllight {
  animation: swing ease-in-out 1.3s infinite alternate;
  transform-origin: 0% 97%;
  transform-box: fill-box;
}

#hairgirl {
  animation: swinghair ease-in-out 1.3s infinite alternate;
  transform-origin: 60% 0%;
  transform-box: fill-box;
}

#zero {
  transform-origin: bottom;
  transform-box: fill-box;
  color: #f47d3a !important;
}

.btn_color2 {
  background-color: #f47d3a !important;
  border: 2px solid #f47d3a;
  border-radius: 10px;
  color: black;
  padding: 7px 60px;
}

.btn_color2:hover {
  background-color: #f47e3a80 !important;
  color: white;
  border: 2px solid #f47d3a;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

@keyframes swinghair {
  0% {
    transform: rotate(6deg) !important;
  }

  100% {
    transform: rotate(-6deg) !important;
  }
}

/*////////////////////////////////////////////////////////// 404 err ///////////////////////////////////////////////////////////////////*/

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .padd {
    margin-top: 150px;
    padding-left: 20px;
  }

  .bgImg {
    background-image: url(./Assets/images/planExpire.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }

  .reject-btn {
    border: 1px solid #f47d3a !important;
    color: white !important;
    background-color: #f47d3a !important;
    padding: 6px 20px 6px 20px !important;
  }
}

@media only screen and (min-width: 567px) and (max-width: 767px) {
  .padd {
    margin-top: 100px;
    padding-left: 20px;
  }

  .bgImg {
    background-image: url(./Assets/images/expire.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }

  .reject-btn {
    border: 1px solid #f47d3a !important;
    color: white !important;
    background-color: #f47d3a !important;
    padding: 4px 25px 4px 25px !important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 576px) {
  .padd {
    margin-top: 100px;
    padding-left: 20px;
  }

  .bgImg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-blend-mode: hue;
  }

  .reject-btn {
    border: 1px solid #f47d3a !important;
    color: white !important;
    background-color: #f47d3a !important;
    padding: 4px 15px 4px 15px !important;
  }
}

.css-xrjn1>div:last-of-type {
  display: none;
}

.css-xrjn1>div:first-of-type {
  display: none;
}

.wrapper {
  width: 200px;
  padding: 20px;
  height: 100px;
}

/* Loader css start */

/* Animation */
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

/* Loading Icon */
.loading {
  width: 100px;
  height: 100px;
}

.loading {
  position: relative;
}

.loading__ring1 {
  position: absolute;
  top: 0px;
  left: 30px;
  transform: skew(30deg, 20deg) scale(1, -1);
}

.loading__ring2 {
  transform: skew(-30deg, -20deg) scale(-1, 1);
}

.loaderImg {
  animation-delay: -0.5s;
}

.loaderImg {
  animation: rotate 1s linear infinite;
  fill: #ff5500;
}

.hover {
  position: relative;
  top: 50px;
  left: 50px;
}

.tooltip {
  /* hide and position tooltip */
  top: -10px;
  background-color: black;
  color: white;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.hover:hover .tooltip {
  /* display tooltip on hover */
  opacity: 1;
}

/* Dashboard CSS */

.dashboard-card {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
  width: 180px;
  height: 80px;
  text-align: center;
  padding: 10px;
  margin-right: 2em;
  border-radius: 10px;
}

.service-card {
  background-color: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
  width: 120px;
  height: 78px;
  text-align: center;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  position: relative;
}

.heading {
  color: #f47d3a;
  font-weight: bold;
  margin-left: 30px;
}

.heading2 {
  color: #f47d3a;
  font-weight: bold;
}

.team {
  width: 92%;
  height: 420px;
  background-color: #fcf6f3;
  margin-left: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.dollar {
  margin-top: 24px;
}

.badge {
  position: absolute;
  left: 70;
  bottom: 85;
  background-color: #f47d3a;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
}

.dash-btn {
  background-color: #fff;
  margin-right: 20px;
  padding: 1px 0px;
  border: 1px solid #f47d3a;
  border-radius: 7px;
  /* width: 15%; */
  width: 25%;
  font-size: 12px;
}

.dash-btn2 {
  background-color: #f47d3a;
  margin-right: 20px;
  padding: 1px 0px;
  border: 1px solid #f47d3a;
  border-radius: 7px;
  /* width: 15%; */
  width: 25%;
  font-size: 12px;
  color: #fff;
}

.colors {
  color: #f47d3a;
  font-weight: bold;
}

.scroll_team {
  overflow-y: auto;
  overflow-x: hidden;
  height: 439px;
  scroll-behavior: smooth;
}

.scroll_client {
  overflow-y: auto;
  overflow-x: hidden;
  height: 420px;
  scroll-behavior: smooth;
}

.scroll_client::-webkit-scrollbar-track,
.scroll_team::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scroll_client::-webkit-scrollbar,
.scroll_team::-webkit-scrollbar {
	width: 8px;
	background-color: #F5F5F5;
}

.scroll_client::-webkit-scrollbar-thumb,
.scroll_team::-webkit-scrollbar-thumb {
	background-color: #C1C1C1;
	border: 2px solid #C1C1C1;
}


.img_Style {
  margin-top: 10px;
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 1566px) {
  .dashboard-card {
    width: 170px;
    height: 80px;
    text-align: center;
    padding: 15px;
    margin-right: 2em;
  }

  .service-card {
    width: 90px;
    margin-right: 10px;
  }

  .team {
    width: 92%;
  }
}

@media only screen and (max-width: 1365px) and (min-width: 1100px) {
  .dashboard-card {
    width: 150px;
    height: 80px;
    text-align: center;
    padding: 15px;
    margin-right: 1.5em;
  }

  .img_Style {
    height: 40px;
    width: 40px;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 1566px) {
  .dashboard-card {
    width: 170px;
    height: 80px;
    text-align: center;
    padding: 15px;
    margin-right: 2em;
  }
}

.scroll {
  height: 75vh;
  overflow: auto;
  scrollbar-width: none
}

.scroll::-webkit-scrollbar {
  display: none;
}

.client-scroll {
  height: 55vh;
  overflow: auto;
}

.client-scroll::-webkit-scrollbar {
  display: none;
}

.service-scroll {
  height: 50vh;
  overflow: scroll;
}

.service-scroll::-webkit-scrollbar {
  display: none;
}

.scrollarea-content {
  margin-right: 15px;
}

.Scrol_Height .scrollarea-content {
  height: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #f1f1f1;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  height: 30px;
}

.chat_scroll {
  height: 80vh;
  width: 100%;
  overflow-x: hidden;
}

.fa-2x {
  font-size: 1.5em;
}

.app {
  position: relative;
  overflow: hidden;
  height: calc(100% - 38px);
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.app-one {
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.side {
  padding: 0;
  margin: 0;
  height: 100%;
}

.side-one {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
  background-color: #fff;
}

.side-two {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

.main-heading {
  padding: 10px 16px 10px 15px;
  margin: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1000;
}

.main-heading2 {
  padding: 10px 16px 10px 15px;
  margin: 0;
  height: 60px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

.heading-avatar {
  padding: 0;
  cursor: pointer;
}

.heading-avatar-icon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.heading-name {
  padding: 0 !important;
  cursor: pointer;
}

.heading-name-meta {
  font-weight: 700;
  font-size: 100%;
  padding: 5px;
  padding-bottom: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  display: block;
}

.heading-online {
  display: none;
  padding: 0 5px;
  font-size: 12px;
  color: #93918f;
}

.heading-compose {
  padding: 0;
}

.heading-compose i {
  text-align: center;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.heading-dot {
  padding: 0;
  margin-left: 10px;
}

.heading-dot i {
  text-align: right;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.chat_searchBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.searchBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fff;
}

.searchBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  height: calc(100% - 120px);
}

.sideBar-body {
  position: relative;
  padding: 10px !important;
  height: 65px;
  cursor: pointer;
  border: 1px solid #f8f9fa;
  border-radius: 10px;
  background: white;
  margin-left: 10px;
  margin-right: 3px;
  width: 95%;
  margin-top: 10px;
  box-shadow: 0 0 2px rgb(204 204 204 / 80%);
}

.sideBar-body-active {
  position: relative;
  padding: 10px !important;
  height: 65px;
  cursor: pointer;
  border: 1px solid #f8f9fa;
  color: #fff;
  border-radius: 10px;
  background: #30323dd1;
  margin-left: 10px;
  margin-right: 3px;
  width: 95%;
  margin-top: 10px;
  box-shadow: 0 0 2px rgb(204 204 204 / 80%);
}

.sideBar-body:hover {
  background-color: #fff;
}

.sideBar-avatar {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main {
  padding: 0 !important;
}

.sideBar-main .row {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name {
  padding-top: 5px !important;
}

.name-meta {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.name-meta-active {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}

.time-meta {
  text-align: right;
  font-size: 12px;
  color: #f47d3a;
  vertical-align: baseline;
}

/*New Message*/

.newMessage {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  position: relative;
  left: -100%;
}

.newMessage-heading {
  padding: 10px 16px 10px 15px !important;
  margin: 0 !important;
  height: 100px;
  width: 100%;
  background-color: #00bfa5;
  z-index: 1001;
}

.newMessage-main {
  padding: 10px 16px 0 15px !important;
  margin: 0 !important;
  height: 60px;
  margin-top: 30px !important;
  width: 100%;
  z-index: 1001;
  color: #fff;
}

.newMessage-title {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 5px !important;
}

.newMessage-back {
  text-align: center;
  vertical-align: baseline;
  padding: 12px 5px !important;
  display: block;
  cursor: pointer;
}

.newMessage-back i {
  margin: auto !important;
}

.composeBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.composeBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

.composeBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.compose-sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 160px);
}

/*Conversation*/

.receive_message {
  width: fit-content;
  padding: 8px 15px;
  border-radius: 0px 20px 20px;
  background: #feeada;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.send_message {
  width: fit-content;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 8px 15px;
  font-size: 12px;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
}

.receive_message_box {
  justify-content: start;
  margin: 5px 0px;
}

.send_message_box {
  justify-content: flex-end;
  margin: 5px 0px;
}

.document_box {
  padding: 10px;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  display: flex;
  align-content: center;
  border-radius: 15px;
  justify-content: space-between;
  width: 45%;
  box-shadow: 0 0 4px rgb(204 204 204 / 40%);
  position: absolute;
  bottom: 60px;
  margin-left: 30px;
}

.chat-doc-box {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  background: #fff;
  min-width: 280px;
  cursor: pointer;
  align-items: center;
  color: #000;
  margin: 15px 10px 10px;
  justify-content: space-between;
  box-shadow: 0 0 4px rgb(239 237 237 / 80%);
}

.chat_Time {
  font-size: 10px;
  margin-top: 5px;
  align-self: end;
}

.receive_Chat_Time {
  font-size: 10px;
  margin-top: 10px;
}

.conversation {
  background-color: #fff;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  background-size: cover;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 120px);
  scroll-behavior: smooth;
}

.message-previous {
  margin: 0 !important;
  padding: 0 !important;
  height: auto;
  width: 100%;
}

.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a {
  text-decoration: none;
  font-weight: 700;
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.message-main-receiver {
  max-width: 60%;
}

.message-main-sender {
  padding: 3px 20px !important;
  margin-left: 40% !important;
  max-width: 60%;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #feeada;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
}

/*Reply*/

.reply {
  width: 100%;
  background-color: #fff;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }

  /* .heading {
    height: 70px;
    background-color: #009688;
  } */

  .fa-2x {
    font-size: 2.3em !important;
  }

  .heading-avatar {
    padding: 0 !important;
  }

  .heading-avatar-icon img {
    height: 50px;
    width: 50px;
  }

  .heading-compose {
    padding: 5px !important;
  }

  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }

  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }

  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }

  .sideBar {
    height: calc(100% - 130px);
  }

  .sideBar-body {
    height: 80px;
  }

  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }

  .avatar-icon img {
    height: 55px;
    width: 55px;
  }

  .sideBar-main {
    padding: 0 !important;
  }

  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }

  .sideBar-name {
    padding: 10px 5px !important;
  }

  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }

  .sideBar-time {
    padding: 10px !important;
  }

  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
  }

  /*Conversation*/
  .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }

  .message {
    height: calc(100% - 140px);
  }

  .reply {
    height: 70px;
  }

  .reply-emojis {
    padding: 5px 0 !important;
  }

  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }

  .reply-main {
    padding: 2px 8px !important;
  }

  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }

  .reply-recording {
    padding: 5px 0 !important;
  }

  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }

  .reply-send {
    padding: 5px 0 !important;
  }

  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}

/* Media query for screens between 768x1024 and 991x1199 (tablets and medium-sized devices) */
/* @media screen and (min-width: 768px) and (min-height: 768px) and (max-width: 1366px) and (max-height: 1199px) {
  .chat_scroll{
    height: 500px;
  }
} */

.chat_scroll::-webkit-scrollbar {
  width: 5px;
}

.chat_scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.chat_scroll::-webkit-scrollbar-thumb {
  background: rgb(175, 170, 170);
  border-radius: 10px;
}

.chat_scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 170, 170);
}

.message::-webkit-scrollbar {
  width: 7px;
}

.message::-webkit-scrollbar-thumb {
  background: rgb(175, 170, 170);
  border-radius: 10px;
  height: 500px;
}

.message::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 170, 170);
}

.vl {
  border-left: 2px solid rgb(235 235 235);
  height: 83%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 55px;
}

.v2 {
  border-left: 2px solid rgb(235 235 235);
  height: 60%;
  position: absolute;
  left: 59%;
  top: 360px;
}

.vlTeam {
  height: 85%;
  position: absolute;
  top: 66px;
}

.div_border {
  border-left: 2px solid rgb(235 235 235);

}

.clip {
  width: 86%;
  height: 275px;
  border-radius: 10px;
  margin: 40px 28px;
}

.steps {
  margin-left: 27px;
  margin-top: 40px;
}

.custom-event-popup {
  max-width: 300px;
}

.event-details {
  max-height: 150px;
  overflow-y: auto;
}

.overlapping-event {
  margin: 5px 0;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font10 {
  font-size: 10px;
}

.rbc-show-more {
  color: #f48522 !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none !important;
}

.wk_Station {
  height: 100%;
  overflow-y: scroll;
}

.wk_Station::-webkit-scrollbar {
  display: none;
}

.my_station {
  height: 65px;
  background: #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
  padding: 10px;
  border-radius: 0.375rem;
  margin: 2px;
}

.fontSize14 {
  font-size: 14px;
}

.mobile_number_input_box input {
  width: 100%;
  position: relative;
  padding: 6px;
  border: 2px solid #f0f0f0 !important;
  border-radius: 5px;
}

.mobile_number_input_box .PhoneInputCountrySelect[disabled] {
  display: none !important;
}

.notfound_image_position {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cart_empty {
  position: absolute;
  top: 28%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.navi_clr {
  color: #297eb3;
}

.star_clr {
  color: red;
}

.cls-1 {
  stroke-width: 16px;
}

.cls-1,
.cls-2 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}

.cls-2 {
  stroke-width: 14px;
}

.cls-3 {
  font-family: CascadiaMono-Regular, 'Cascadia Mono';
  font-size: 153.12px;
  font-variation-settings: 'wght' 400;
}

.icons_size {
  transform: scale(0.6);
}

.jodit-status-bar {
  display: none !important;
}

.chip {
  background: rgb(244, 125, 58) !important;
}

.Ticket_Attachment img {
  width: 100% !important;
  height: 100px;
  object-fit: cover !important;
  border-radius: 5px;
}

.Ticket_Attachment span {
  position: absolute;
  right: 12px;
  color: black;
  font-size: 18px;
  top: -10px;
  cursor: pointer;
}

.Ticket_Attachment video {
  width: 100% !important;
  height: 100px;
  object-fit: cover !important;
  border-radius: 5px;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color);
  background-color: #f47d3a !important;
  border-color: #f47d3a !important;
}

.btn-outline-secondary {}

.btn-outline-secondary {
  --bs-btn-color: #f47d3a !important;
  --bs-btn-border-color: #f47d3a !important;
}

.btn-group> :not(.btn-check:first-child)+.btn {
  border-color: #f47d3a;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #f47d3a;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #f47d3a !important;
}

._cart {
  border-radius: 18px;
  margin: 50px 0;
  min-height: 20vh;
}

.payment {
  padding: 25px;
  box-shadow: 0 0 13px #97a7c37a, 0 2px 13px 0 #97a7c3ab;
  border-radius: 10px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 450px;
  min-height: 582px;
}

._paymentCard {
  background: #f47d3a;
  border-radius: 5px;
  color: #fff;
}

.cus-pay-wrapper {
  box-shadow: 0 0 13px #97a7c37a, 0 2px 13px 0 #97a7c3ab;
  padding: 15px;
  border-radius: 9px;
  background: #fff;
  margin-top: 20px;
}

.applied_promo {
  background: #f47d3a;
  padding: 6px;
  display: inline;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 500;
}

.cus-contact-form {
  border: 2px solid #ececec;
  padding: 0 15px;
  border-radius: 15px;
  /* margin-bottom: 45px; */
  margin-top: 50px;
  margin-left: 300px;
  margin-right: 300px;
}

.changeClass p {
  display: inline !important;
}

/* pricing plan css start */
.all_plan_main_border_radius {
  border-radius: 20px;
}

.free_plan {
  height: 250px;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 65%, 0 50%);
  border-radius: 20px;
  background: rgb(118, 71, 212);
  background: linear-gradient(148deg,
      rgba(118, 71, 212, 1) 0%,
      rgba(167, 59, 203, 1) 100%);
  margin-top: -25px;
}

.free_pro {
  height: 250px;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 65%, 0 50%);
  border-radius: 20px;
  background: rgb(118, 71, 212);
  background: linear-gradient(148deg,
      rgba(236, 117, 96, 1) 0%,
      rgba(254, 158, 141, 1) 100%);
  margin-top: -25px;
}

.free_advance {
  height: 250px;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 65%, 0 50%);
  border-radius: 20px;
  background: rgb(118, 71, 212);
  background: linear-gradient(148deg,
      rgba(41, 176, 144, 1) 0%,
      rgba(183, 204, 85, 1) 100%);
  margin-top: -25px;
}

.free_custom {
  height: 100px;
  /* clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 65%, 0 50%); */
  /* border-radius: 20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #f47d3a;
  background: linear-gradient(148deg,
      #f47d3a 0%,
      #ff9e4f 100%);
  margin-top: -25px;
}

.current {
  height: 180px;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 65%, 0 50%);
  border-radius: 20px;
  background: rgb(118, 71, 212);
  background: linear-gradient(148deg,
      rgba(0, 42, 201, 1) 0%,
      rgba(1, 121, 234, 1) 100%);
  margin-top: -25px;
}

.free_plan_text {
  margin-top: -80px;
}

.list_item_gray_bg {
  background-color: #f8f8f8;
}

.col-lg-12 p {
  font-size: 13px;
  font-weight: 500;
}

.padding_list_item {
  padding: 6px 0;
  margin: 0;
}

.free_button button {
  border-radius: 100px !important;
  padding: 6px 2px 5px 2px;
  background: linear-gradient(148deg,
      rgba(118, 71, 212, 1) 0%,
      rgba(167, 59, 203, 1) 100%);
  color: #ffffff;
}

.pro_button button {
  border-radius: 100px !important;
  padding: 6px 2px 5px 2px;
  background: linear-gradient(148deg,
      rgba(236, 117, 96, 1) 0%,
      rgba(254, 158, 141, 1) 100%);
  color: #ffffff;
}

.advance_button button {
  border-radius: 100px !important;
  padding: 6px 2px 5px 2px;
  background: linear-gradient(148deg,
      rgba(41, 176, 144, 1) 0%,
      rgba(183, 204, 85, 1) 100%);
  color: #ffffff;
}

.custom_button button {
  border-radius: 100px !important;
  padding: 6px 2px 5px 2px;
  background: linear-gradient(148deg,
      rgba(0, 42, 201, 1) 0%,
      rgba(1, 121, 234, 1) 100%);
  color: #ffffff;
}

.free_plan_color {
  color: rgba(167, 59, 203, 1);
  font-weight: bold;
}

.pro_plan_color {
  color: rgba(236, 117, 96, 1);
  font-weight: bold;
}

.advance_plan_color {
  color: rgba(41, 176, 144, 1);
  font-weight: bold;
}

.col-lg-12>.text-muted {
  color: #bababa !important;
}

.main_color_iskedo {
  color: #f17d4b;
}

.btn_Styles {
  background: #fc8271;
  color: #fff;
  font-size: 15px;
  border-radius: 6px;
}

.btn_Styles:hover {
  background: #fc8271;
  color: #fff;
  font-size: 15px;
  border-radius: 6px;
}

.btn_Style-4 {
  background: #1175b7;
  color: #fff;
  font-size: 15px;
  border-radius: 6px;
}

.btn_Style-4:hover {
  background: #1175b7;
  color: #fff;
}

.custom_table_head_color tr th {
  color: #adadad;
  font-weight: 500;
  font-size: 15px;
}

.delete_cart_btn {
  border: 2px solid rgb(210, 209, 209);
}

/* pricing plan css end */

._teamSelect {
  border-radius: 7px;
  padding: 15px;
  border: 0.05rem solid #ced4da;
}

.client_ms {
  margin-left: 1rem;
}

.cards {
  border: none;
  width: 400px;
  border-radius: 12px;
  color: #fff;
  background-image: linear-gradient(to right top,
      #280537,
      #56034c,
      #890058,
      #bc005b,
      #eb1254);
}

.number span {
  font-size: 20px;
  font-weight: 600;
}

.cardholder,
.expires {
  font-weight: 600;
  font-size: 17px;
}

.name,
.date {
  font-size: 15px;
}

.card-border {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border: none;
  border-radius: 6px;
  background-color: blue;
  color: #fff;
  background-image: linear-gradient(to right top,
      #0c0537,
      #440b51,
      #7f005d,
      #b9005b,
      #eb124b);
}

/* Multiple Profile CSS Start */
._card_Main {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.profile_Card{
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  display: flex;
  overflow: auto;
  max-height: 80vh;
  /* padding-top: 70px !important; */
}
.profile_Card::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.profile_Card::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.profile_Card::-webkit-scrollbar-thumb
{
	background-color: #c0bcbc;
	border: 2px solid #c0bcbc;
}

.scroll_Card {
  width: 30vw;
  height: 50vh;
  background-color: #ff84007a;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: #000000;
}
.No_Location_card {
  background-color: #fff;
  width: 330px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px !important;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}
._card {
  background-color: #fff;
  width: 330px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.6rem !important;
  margin: 10px 0px;
}

._card2 {
  background-color: grey;
  width: 330px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.6rem !important;
  margin: 10px 0px;
}

.top-container {
  display: flex;
  align-items: center;
}

.profile-image {
  border-radius: 10px;
  border: 2px solid #5957f9;
}

/* Initial styles for the _card element */
._card {
  position: relative;
}

/* Arrow icon style */
._card::before {
  content: '➜';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: 20px;
  margin-bottom: 10px;
}

/* Define a CSS animation for the arrow icon */
@keyframes arrowAnimation {
  0% {
    right: 10px;
    transform: translateY(-50%);
    opacity: 0;
  }

  50% {
    right: 20px;
    transform: translateY(-50%) translateX(5px);
    opacity: 1;
  }

  100% {
    right: 10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

/* Apply the animation on hover */
._card:hover::before {
  animation: arrowAnimation 1s infinite;
}

.frame-container {
  border: 10px solid #3498db;
  /* Choose your desired frame color */
  border-radius: 15px;
  overflow: hidden;
  display: inline-block;
}

/* Multiple Profile CSS End */

.chatTabberNotification {
  background-color: #f47d3a;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-top: 17px;
}

/* Mui Autocomplete CSS Start */
/* .css-1kxbtff-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  background-color: #fcf6f3 !important;
  box-shadow: 0px 1px 3px #fbbf9e !important;

} */
.css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
  background-color: #f47d3a !important;
  color: #fff !important;
}

.css-1kp1dnc-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  color: white !important;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-style: none !important;
} */
/* Mui Autocomplete CSS End */
.css-13cymwt-control {
  background-color: #fcf6f3 !important;
  box-shadow: 0px 1px 3px #fbbf9e !important;
  border: none !important;
}

._icon {
  fill: black;
  margin-right: 10px;
  font-size: 20px;
  margin-top: 2px;
}

.hr_Style {
  margin-top: 2px;
  margin-bottom: 8px;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff !important;
  background-color: #f47d3a !important;
}

#scroll-container {
  border-radius: 5px;
  overflow: hidden;
  width: 700px;
}

#scroll-text {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 25s linear infinite;
  -webkit-animation: my-animation 25s linear infinite;
  animation: my-animation 25s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

aside.EmojiPickerReact.epr-main {
  border-color: var(--epr-picker-border-color);
  border-radius: var(--epr-picker-border-radius);
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 289px !important;
  margin-left: 5px !important;
  height: 390px !important;
}

/* In your CSS file or inline styles */
.marquee-container {
  overflow: hidden;
  width: 60%;
}

.marquee-text {
  display: inline-block;
  white-space: nowrap;
  animation: marqueeAnimation 30s linear infinite;
}

@keyframes marqueeAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}



.phone_Input {
  border-radius: 5px;
  padding: 5px 7px;
  background-color: #fcf6f3;
  border: 1px solid #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.phone_Input2 {
  border-radius: 5px;
  padding: 7px 58px;
  background-color: #fcf6f3;
  border: 1px solid #fcf6f3;
  box-shadow: 0px 1px 3px #fbbf9e;
}

.phone_Input_Contact {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  /* box-shadow: 0px 1px 3px #fbbf9e; */
}

.PhoneInputInput {
  border: none;
  /* background: #fcf6f3; */
  background: transparent;
  outline: none;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
  display: none;
}

.text_Grey {
  color: gray;
}

.plan_Btn {
  background: #fff;
  border: 2px solid #f47d3a;
  color: #f47d3a;
  border-radius: 8px;
  width: 120px;
}

.plan_Btn:hover {
  background: #fff;
  border: 2px solid #f47d3a;
  color: #f47d3a;
  border-radius: 8px;
  width: 120px;
}

.show_Cancel {
  /* display: block;
   */
  opacity: 1;
  transition: all 1s;
}

.hidden_Cancel {
  /* display: none; */
  opacity: 0;
  transition: all 1s;
}


/* media query start */

.webhide {
  display: none;
}

.bgoffcanvas {
  background-color: #f47d3a;
}

.padding_ps_5 {
  padding-left: 3rem;
}

.dashboard_main {
  background: rgb(255, 255, 255);
  border-radius: 15px;
  margin: 0px;
  width: 98%;
}

.toggle_btn_font {
  font-size: 14px;
}

.pdf_hw {
  height: 500px;
  width: 1000px;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  height: 20px !important;
}

/* Permission Animation */
.inner {
  position: relative;
  overflow: hidden;
}

.hide2 {
  float: left;
  clear: both;
  opacity: 1;
  overflow: hidden;
  transform: translateY(0);
  margin: 0;
  transition: all 1.5s linear, margin 0s linear 0s;
}

.ex2 input[type="checkbox"]:not(:checked)+.inner .hide2 {
  opacity: 0;
  transform: translateY(-100%);
  margin-bottom: -999em;
  transition: all 1.5s linear, margin 0s linear 1.5s;
}


/* Login Page Multiple Profile */
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.four {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  animation-delay: .3s;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.USER-PROFILE {
  height: 250px;
}

.USER-PROFILE::-webkit-scrollbar {
  display: none;
}

._card_Login {
  background-color: #fcf6f3;
  width: 330px;
  border-radius: 10px;
  padding: 0.6rem !important;
  margin: 10px 10px;
}

._card_Login img {
  border-radius: 50%;
  /* border:2px solid #F7A373; */
}

._card_Login_Shadow {
  background-color: #fcf6f3;
  width: 330px;
  border-radius: 10px;
  padding: 0.6rem !important;
  margin: 10px 10px;
  border: 2px solid #F7A373;
}

._card_Login_Shadow img {
  border-radius: 50%;
  /* border:2px solid #F7A373; */
}

.disable_Btn {
  border: 1px solid grey;
  opacity: var(--bs-btn-disabled-opacity);
  background-color: #fff;
  border-radius: 24px;
  color: grey;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 0px;
  width: 200px;
  /* cursor: auto; */
  cursor: not-allowed;
}

.disable_Btn:hover {
  background-color: #fff;
  border: 1px solid grey;
}

.animate h1 {
  font-size: 30px;
}

.qr_video {
  width: 360px;
  height: 300px;
  ;
}


/* app not found css start */
.appnotfound_bg {
  background: rgb(241, 127, 117);
  background: linear-gradient(145deg, rgba(241, 127, 117, 1) 0%, rgba(244, 170, 124, 1) 100%, rgba(0, 212, 255, 1) 100%);
}

.appnotfound_mainbg {
  /* background-color: rgba(218, 218, 218, 0.068); */
  width: 350px;
  border-radius: 12%;
}

/* app not found css start */
/* session css start */
.session {
  background: #f47d3a;
  border-radius: 5px;
  margin: 5px 0px;
  color: #fff;
}

.combo_Scroll {
  height: 40vh;
  overflow-x: hidden;
  /* overflow: scroll; */
}

.session_Scroll {
  overflow: auto;
  height: 550px
}

.session_Scroll::-webkit-scrollbar {
  display: none;
}

/* .combo_Scroll::-webkit-scrollbar {
  display: none;
} */
.combo_Main {
  /* height: 20vh; */
  border: 1px solid #e3e5e8;
}

.sessions2 {
  min-height: 5vh;
  border: 2px solid #5593ef;
  border-radius: 10px;
}

.sessions {
  min-height: 5vh;
  border: 1px solid #e3e5e8;
  border-radius: 10px;
}

.service_Count {
  background: aliceblue;
  border-radius: 50%;
  padding: 2px 3px;
  height: 30px;
  width: 30px;
}

.blurColor {
  color: #5593ef;
}

/* Time Picker css start*/
/* .custom-time-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
} */
.custom-time-picker {
  /* width: 40%; */
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-time-picker input {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 16px;
  /* background: #fcf6f3; */
}

.custom-time-picker .react-time-picker__wrapper {
  display: flex;
  align-items: center;
}

.custom-time-picker .react-time-picker__inputGroup {
  display: flex;
  align-items: center;
}

.custom-time-picker .react-time-picker__inputGroup__input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  width: 30px;
  text-align: center;
}

/* Payment Card CSS */

.card_Main h1 {
  color: #88B04B;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.card_Main p {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.card_Main i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: 50px;
}

.Success_Card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 5px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}

@media screen and (min-width: 668px) and (max-width: 992px) {
  .phonehide {
    display: none !important;
  }

  .webhide {
    display: block;
  }

  .main-background {
    background-color: #feeada;
    height: auto;
    overflow: auto;
  }

  .offcanvas-body {
    flex-grow: 1;
    padding: 2px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  aside {
    color: black;
    background-color: #feeada;
    overflow: scroll;
    margin: 0px 0 !important;
    padding: 12px !important;
  }

  .menu-list {
    height: auto !important;
    overflow: auto;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
  }

  .shadow_none>.shadow {
    box-shadow: none !important;
  }

  .heading_font>h6 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .client_ms {
    margin-left: 8px;
  }

  .tooltip.show {
    display: none;
  }

  .page-tittle {
    font-size: 20px;
    padding: 0 8px 0 0;
    margin-top: 4px;
  }

  .dashboard-card {
    background-color: #fcf6f3;
    box-shadow: 0px 1px 3px #fbbf9e;
    width: 100%;
    height: 80px;
    text-align: center;
    padding: 10px;
    margin-right: 2em;
    border-radius: 10px;
  }

  .padding_ps_5 {
    padding-left: 20px;
    padding-right: 0px !important;
  }

  .cus-padd {
    padding: 12px;
    width: 100%;
    border-radius: 15px;
  }

  .m-t-3 {
    margin-top: 0px !important;
  }

  .dashboard_main {
    background: rgb(255, 255, 255);
    border-radius: 0px;
    margin: 0px;
    width: 100%;
  }

  .listpadding {
    padding-left: 12px;
    padding-right: 12px;
  }

  /* .dash-btn2 {
    margin-right: 24px;
  }

  .dash-btn {
    margin-right: 24px;
  } */

  .margin_mb_4 {
    margin-bottom: 12px;
  }

  .heading {
    margin-left: 0px;
  }

  .service-card {
    width: 100%;
    margin-right: 0px;
  }

  /* .custom_input_width {
    width: 100% !important;
  } */

  .Service_box {
    margin-left: 0px !important;
  }

  .Service_box div {
    margin: 0px 14px 12px 4px !important;
  }

  .team {
    margin-left: 0px !important;
    width: 100% !important;
    height: 340px;
  }

  .scroll {
    height: 75vh;
    overflow-x: hidden;
  }


  .addbtn2 {
    position: absolute;
    bottom: 10% !important;
    left: 85%;
  }

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    --bs-offcanvas-width: 378px !important;
    padding: 8px !important;
  }

  .offcanvas.offcanvas-end {
    top: 0px;
    right: 0px;
    bottom: 0px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .calender_web_height {
    height: 400px !important;
  }

  .rbc-toolbar {
    flex-wrap: nowrap !important;
    margin: 12px 0 20px 0 !important;
    /* font-size: 8px !important; */
  }

  .table th {
    font-size: 14px !important;
  }

  .table td {
    font-size: 12px !important;
  }

  .cus-table,
  .readtable {
    width: 210% !important;
  }

  .filter_dashboard_all {
    width: 32% !important;
  }

  .popupbtn {
    position: inherit;
    bottom: 44px !important;
    left: 28% !important;
  }

  .fa-plus:before {
    margin-left: 12px !important;
  }

  .fa-minus:before {
    margin-left: 12px !important;

  }

  .inputwithicon i,
  .inputwithicon1 i {
    left: 90% !important;
  }

  .notfound_image_position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .notfound_image_position img {
    height: 300px;
    width: 300px;
  }

  .scroll {
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .fa-plus:before {
    cursor: pointer;
    background: #f47d3a;
    color: #fff;
    border-radius: 100%;
    padding: 10.5px 12px !important;
    font-size: 20px !important;
    outline: none;
  }

  .scrollarea-content {
    margin-right: 0px;
  }

  .avatar-icon img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .sideBar-body {
    height: 62px;
  }

  .chat_name {
    font-size: 15px;
    font-weight: 600;
  }

  .name-meta {
    padding: 0% !important;
  }

  .chat_notification {
    background: #f47d3a;
    border-radius: 50%;
    font-size: 10px;
    padding: 4px 7.5px;
    color: #fff;
  }

  .conversation {
    border-left: none;
  }

  .form-control>option {
    font-size: 12px !important;
  }

  .overlay {
    opacity: 1;
    border-radius: 0 0 9px 9px;
  }

  .card1 {
    background-color: #fcf6f3;
    box-shadow: 0px 1px 3px #fbbf9e;
    width: 100%;
    height: 100px;
    text-align: center;
    padding: 20px;
    margin-right: 0;
    border-radius: 10px;
  }

  /* .recharts-wrapper>svg {
    width: 700px;
    height: auto;
  }

  .recharts-wrapper {
    position: relative;
    cursor: default;
    width: 800px;
    height: auto !important;
  }

  .recharts-legend-wrapper {
    position: absolute;
    width: auto;
    height: auto;
    left: 20px;
    bottom: 5px;
  } */

  /* .recharts-default-legend {
    display: none;
  } */

  /* .recharts-tooltip-wrapper {
    pointer-events: none;
    visibility: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(200px, 77px) !important;
    transition: -webkit-transform 400ms ease 0s;
  } */

  .vlTeam {
    border-left: none;
    height: auto;
    position: absolute;
    top: 66px;
  }

  .my_station {
    /* height: 65px; */
    background: #fcf6f3;
    box-shadow: 0px 1px 3px #fbbf9e;
    padding: 10px;
    border-radius: 0.375rem;
    margin: 2px;
  }

  .div_border {
    border-left: none;
  }

  /* .team_member {
    margin-top: 70px !important;
  } */
  .container2 {
    position: relative;
    width: 100% !important;
    background-color: #fff;
    height: 90vh;
    margin-top: 0% !important;
    overflow: hidden;
    box-shadow: none;
  }

  .container {
    min-width: 100% !important;
  }

  .qr_video {
    width: 460px;
    height: 300px;
    ;
  }

  .addbtn3 {
    position: absolute;
    bottom: 12.5%;
  }

  .pdf_hw {
    width: 776px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .phonehide {
    display: none !important;
  }

  .webhide {
    display: block;
  }

  .main-background {
    background-color: #feeada;
    height: auto;
    overflow: auto;
  }

  .offcanvas-body {
    flex-grow: 1;
    padding: 2px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  aside {
    color: black;
    background-color: #feeada;
    overflow: scroll;
    margin: 0px 0 !important;
    padding: 12px !important;
  }

  .menu-list {
    height: auto !important;
    overflow: auto;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
  }

  .shadow_none>.shadow {
    box-shadow: none !important;
  }

  .heading_font>h6 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .client_ms {
    margin-left: 8px;
  }

  .tooltip.show {
    display: none;
  }

  .page-tittle {
    font-size: 20px;
    padding: 0 8px 0 0;
    margin-top: 4px;
  }

  .dashboard-card {
    background-color: #fcf6f3;
    box-shadow: 0px 1px 3px #fbbf9e;
    width: 100%;
    height: 80px;
    text-align: center;
    padding: 10px;
    margin-right: 2em;
    border-radius: 10px;
  }

  .padding_ps_5 {
    padding-left: 20px;
    padding-right: 0px !important;
  }

  .cus-padd {
    padding: 12px;
    width: 100%;
    border-radius: 15px;
  }

  .m-t-3 {
    margin-top: 0px !important;
  }

  .dashboard_main {
    background: rgb(255, 255, 255);
    border-radius: 0px;
    margin: 0px;
    width: 100%;
  }

  .listpadding {
    padding-left: 12px;
    padding-right: 12px;
  }

  .dash-btn2 {
    margin-right: 24px;
  }

  .dash-btn {
    margin-right: 24px;
  }

  .margin_mb_4 {
    margin-bottom: 12px;
  }

  .heading {
    margin-left: 0px;
  }

  .service-card {
    width: 100%;
    margin-right: 0px;
  }

  .custom_input_width {
    width: 100% !important;
  }

  .Service_box {
    margin-left: 0px !important;
  }

  .Service_box div {
    margin: 0px 14px 12px 4px !important;
  }

  .team {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .scroll {
    height: 75vh;
    overflow-x: hidden;
  }

  .addbtn2 {
    position: absolute;
    bottom: 10% !important;
    left: 78%;
  }

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    --bs-offcanvas-width: 378px !important;
    padding: 8px !important;
  }

  .offcanvas.offcanvas-end {
    top: 0px;
    right: 0px;
    bottom: 0px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .calender_web_height {
    height: 400px !important;
  }

  .rbc-toolbar {
    flex-wrap: nowrap !important;
    margin: 12px 0 20px 0 !important;
    font-size: 8px !important;
  }

  .table th {
    font-size: 14px !important;
  }

  .table td {
    font-size: 12px !important;
  }

  .cus-table,
  .readtable {
    width: 210% !important;
  }

  .filter_dashboard_all {
    width: 32% !important;
  }

  .popupbtn {
    position: inherit;
    bottom: 44px !important;
    left: 28%;
  }

  .popupbtn_filter {
    position: absolute;
    bottom: 10% !important;
    /* left: 28%; */
  }

  .fa-plus:before {
    margin-left: 12px !important;
  }

  .fa-minus:before {
    margin-left: 12px !important;

  }

  .inputwithicon i,
  .inputwithicon1 i {
    left: 90% !important;
  }

  .notfound_image_position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .notfound_image_position img {
    height: 300px;
    width: 300px;
  }

  .scroll {
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .fa-plus:before {
    cursor: pointer;
    background: #f47d3a;
    color: #fff;
    border-radius: 100%;
    padding: 10.5px 12px !important;
    font-size: 20px !important;
    outline: none;
  }

  .scrollarea-content {
    margin-right: 0px;
  }

  .avatar-icon img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .sideBar-body {
    height: 62px;
  }

  .chat_name {
    font-size: 15px;
    font-weight: 600;
  }

  .name-meta {
    padding: 0% !important;
  }

  .chat_notification {
    background: #f47d3a;
    border-radius: 50%;
    font-size: 10px;
    padding: 4px 7.5px;
    color: #fff;
  }

  .conversation {
    border-left: none;
  }

  .form-control>option {
    font-size: 12px !important;
  }

  .overlay {
    opacity: 1;
    border-radius: 0 0 9px 9px;
  }

  .card1 {
    background-color: #fcf6f3;
    box-shadow: 0px 1px 3px #fbbf9e;
    width: 100%;
    height: 100px;
    text-align: center;
    padding: 20px;
    margin-right: 0;
    border-radius: 10px;
  }

  .recharts-wrapper>svg {
    width: 370px;
    height: auto;
  }

  .recharts-wrapper {
    position: relative;
    cursor: default;
    width: 800px;
    height: 280px !important;
  }

  .recharts-legend-wrapper {
    position: absolute;
    width: 320px !important;
    height: auto;
    left: 20px;
    bottom: 5px;
  }

  .recharts-default-legend {
    display: none;
  }

  .recharts-tooltip-wrapper {
    pointer-events: none;
    visibility: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(200px, 77px) !important;
    transition: -webkit-transform 400ms ease 0s;
  }

  .vlTeam {
    border-left: none;
    height: auto;
    position: absolute;
    top: 66px;
  }

  .my_station {
    /* height: 65px; */
    background: #fcf6f3;
    box-shadow: 0px 1px 3px #fbbf9e;
    padding: 10px;
    border-radius: 0.375rem;
    margin: 2px;
  }

  .div_border {
    border-left: none;
  }

  .team_member {
    margin-top: 70px !important;
  }

  .mywidth {
    width: 100% !important;
  }

  .pdf_hw {
    height: 500px;
    width: 370px;
  }

  .qr_video {
    width: 340px;
    height: 300px;
    ;
  }

  .rbc-calendar *,
  .rbc-calendar *:before,
  .rbc-calendar *:after {
    box-sizing: inherit;
    font-size: 10px !important;
  }

  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 !important;
    font-size: 8px !important;
    text-align: center;
  }

  .addbtn3 {
    position: absolute;
    bottom: 8.5%;
  }
}

/* media query end */